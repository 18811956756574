import { createStore } from 'vuex';
import VuexPersistence from 'vuex-persist';

const vuexLocal = new VuexPersistence({
    storage: window.sessionStorage
});

const store = createStore({
    state: {
        projectName: '',
        length: "35",
        width: "25",
        height: "15",
        temperature: "17",
        targetValue: "2",
        applied: false,
        roof: [],
        firstGableWall: [],
        secondGableWall: [],
        firstSideWall: [],
        secondSideWall: [],
        roofColor: '',
        firstGableWallColor: '',
        secondGableWallColor: '',
        firstSideWallColor: '',
        secondSideWallColor: '',
        /* ac63cm = Absorption coefficient 63 custom material*/
        ac63cm: '0.05',
        ac125cm: '0.05',
        ac250cm: '0.22',
        ac500cm: '0.49',
        ac1000cm: '0.90',
        ac2000cm: '0.98',
        ac4000cm: '0.90',
        customMaterialName: '' 
    },
    mutations: {
        MUTATE_PROJECT_NAME(state, payload) {
            state.projectName = payload;
        },
        MUTATE_LENGTH(state, payload) {
            state.length = payload;
        },
        MUTATE_WIDTH(state, payload) {
            state.width = payload;
        },
        MUTATE_HEIGHT(state, payload) {
            state.height = payload;
        },
        MUTATE_TEMPERATURE(state, payload) {
            state.temperature = payload;
        },
        MUTATE_TARGET_VALUE(state, payload) {
            state.targetValue = payload;
        },
        MUTATE_APPLIED(state, payload) {
            state.applied = payload;
        },
        MUTATE_ROOF(state, payload) {
            state.roof = payload;
        },
        MUTATE_FIRST_GABLE_WALL(state, payload) {
            state.firstGableWall = payload;
        },
        MUTATE_SECOND_GABLE_WALL(state, payload) {
            state.secondGableWall = payload;
        },
        MUTATE_FIRST_SIDE_WALL(state, payload) {
            state.firstSideWall = payload;
        },
        MUTATE_SECOND_SIDE_WALL(state, payload) {
            state.secondSideWall = payload;
        },
        MUTATE_ROOF_COLOR(state, payload) {
            state.roofColor = payload;
        },
        MUTATE_FIRST_GABLE_WALL_COLOR(state, payload) {
            state.firstGableWallColor = payload;
        },
        MUTATE_SECOND_GABLE_WALL_COLOR(state, payload) {
            state.secondGableWallColor = payload;
        },
        MUTATE_FIRST_SIDE_WALL_COLOR(state, payload) {
            state.firstSideWallColor = payload;
        },
        MUTATE_SECOND_SIDE_WALL_COLOR(state, payload) {
            state.secondSideWallColor = payload;
        },
        MUTATE_CUSTOM_MATERIAL_NAME(state, payload) {
            state.customMaterialName = payload;
        },
        MUTATE_AC_63_CM(state, payload) {
            state.ac63cm = payload;
        },
        MUTATE_AC_125_CM(state, payload) {
            state.ac125cm = payload;
        },
        MUTATE_AC_250_CM(state, payload) {
            state.ac250cm = payload;
        },
        MUTATE_AC_500_CM(state, payload) {
            state.ac500cm = payload;
        },
        MUTATE_AC_1000_CM(state, payload) {
            state.ac1000cm = payload;
        },
        MUTATE_AC_2000_CM(state, payload) {
            state.ac2000cm = payload;
        },
        MUTATE_AC_4000_CM(state, payload) {
            state.ac4000cm = payload;
        },
    },
    actions: {
        updateProjectName(context, payload) {
            context.commit("MUTATE_PROJECT_NAME", payload);
        },
        updateLength(context, payload) {
            context.commit("MUTATE_LENGTH", payload);
        },
        updateWidth(context, payload) {
            context.commit("MUTATE_WIDTH", payload);
        },
        updateHeight(context, payload) {
            context.commit("MUTATE_HEIGHT", payload);
        },
        updateTemperature(context, payload) {
            context.commit("MUTATE_TEMPERATURE", payload);
        },
        updateTargetValue(context, payload) {
            context.commit("MUTATE_TARGET_VALUE", payload);
        },
        updateApplied(context, payload) {
            context.commit("MUTATE_APPLIED", payload);
        },
        updateRoof(context, payload) {
            context.commit("MUTATE_ROOF", payload);
        },
        updateFirstGableWall(context, payload) {
            context.commit("MUTATE_FIRST_GABLE_WALL", payload);
        },
        updateSecondGableWall(context, payload) {
            context.commit("MUTATE_SECOND_GABLE_WALL", payload);
        },
        updateFirstSideWall(context, payload) {
            context.commit("MUTATE_FIRST_SIDE_WALL", payload);
        },
        updateSecondSideWall(context, payload) {
            context.commit("MUTATE_SECOND_SIDE_WALL", payload);
        },
        updateRoofColor(context, payload) {
            context.commit("MUTATE_ROOF_COLOR", payload);
        },
        updateFirstGableWallColor(context, payload) {
            context.commit("MUTATE_FIRST_GABLE_WALL_COLOR", payload);
        },
        updateSecondGableWallColor(context, payload) {
            context.commit("MUTATE_SECOND_GABLE_WALL_COLOR", payload);
        },
        updateFirstSideWallColor(context, payload) {
            context.commit("MUTATE_FIRST_SIDE_WALL_COLOR", payload);
        },
        updateSecondSideWallColor(context, payload) {
            context.commit("MUTATE_SECOND_SIDE_WALL_COLOR", payload);
        },
        updateAc63Cm(context, payload) {
            context.commit("MUTATE_AC_63_CM", payload);
        },
        updateCustomMaterialName(context, payload) {
            context.commit("MUTATE_CUSTOM_MATERIAL_NAME", payload);
        },
        updateAc125Cm(context, payload) {
            context.commit("MUTATE_AC_125_CM", payload);
        },
        updateAc250Cm(context, payload) {
            context.commit("MUTATE_AC_250_CM", payload);
        },
        updateAc500Cm(context, payload) {
            context.commit("MUTATE_AC_500_CM", payload);
        },
        updateAc1000Cm(context, payload) {
            context.commit("MUTATE_AC_1000_CM", payload);
        },
        updateAc2000Cm(context, payload) {
            context.commit("MUTATE_AC_2000_CM", payload);
        },
        updateAc4000Cm(context, payload) {
            context.commit("MUTATE_AC_4000_CM", payload);
        },
    },
    plugins: [vuexLocal.plugin],
});

export default store;